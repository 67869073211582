<template>
  <prototype-attribute-table attribute-name="merchandisers" />
</template>

<script>
import PrototypeAttributeTable from '../components/PrototypeAttributeTable.vue'

export default {
  components: {
    PrototypeAttributeTable
  }
}
</script>

<style scoped>
</style>

import { render, staticRenderFns } from "./MerchandiserManagement.vue?vue&type=template&id=c33b0162&scoped=true&"
import script from "./MerchandiserManagement.vue?vue&type=script&lang=js&"
export * from "./MerchandiserManagement.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c33b0162",
  null
  
)

export default component.exports